import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const SimpleTag = forwardRef((props, ref) => {
    const { className, children, rest, icon, childrenClassName, keyIndex } = props;

    return (
        <div
            className={classNames("flex items-center px-2 py-1 rounded-full", className)}
            ref={ref}
            {...rest}
            key={keyIndex}
        >
            {icon && <span className="mr-1">{icon}</span>}
            <span
                className={classNames("ml-1 rtl:mr-1 whitespace-nowrap font-extrabold", childrenClassName)}
                key={keyIndex}
            >
                {children}
            </span>
        </div>
    );
});

SimpleTag.defaultProps = {
    prefix: false,
    suffix: false,
};

SimpleTag.propTypes = {
    prefix: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
    suffix: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
    prefixClass: PropTypes.string,
};

export default SimpleTag;
