import React from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import appConfig from "../configs/app.config";
import { Tag } from "../components/ui";
import moment from "moment";
import PhoneNumberFormatter from "../utils/phoneNumberFormat";
import { IconText } from "../components/shared";
import navigationIcon from "../configs/navigation-icon.config";

export const APP_NAME = "Treply";
export const PERSIST_STORE_NAME = "admin";
export const ACCESS_TOKEN = "accessToken";
export const REDIRECT_URL_KEY = "redirectUrl";
export const AUTHORITY_ADMIN = "admin";
export const AUTHORITY_USER = "user";
export const AUTHORITY_UNPAID = "unpaid";
export const AUTHORITY_CADMIN = "cadmin";

export const PLAN_FREE = "Free";
export const PLAN_TICKLE = "Tickle";
export const PLAN_TURBO = "Turbo";
export const PLAN_BOOSTER = "Booster";
export const PLAN_ENTERPRISE = "Enterprise";

export const USER_ONBOARDING_STEPS = [
    {
        stepName: "USER_DETAILS",
        displayName: "Enter User Details",
        index: 0,
        isComplete: false,
    },
    {
        stepName: "ORGANIZATION_DETAILS",
        displayName: "Enter Organization Details",
        index: 1,
        isComplete: false,
    },
    {
        stepName: "PLAN_PAYMENT",
        displayName: "Choose Pricing Plan and Checkout",
        index: 2,
        isComplete: false,
    },
    {
        stepName: "COMPLETED",
        displayName: "Completed Onboarding steps",
        index: 2,
        isComplete: false,
    },
];
export const HTTP_STATUS_CODES = {
    OK: 200,
    CREATED: 201,
    ACCEPTED: 202,
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    NOT_FOUND: 404,
    LOGGED_IN: 177,
    USER_INFO: 179,
    INTERNAL_SERVER: 500,
    ERR_NETWORK: "ERR_NETWORK",
};

export const FREE_PLAN = "Free";
export const TICKLE_PLAN = "Tickle";
export const TURBO_PLAN = "Turbo";
export const BOOSTER_PLAN = "Booster";
export const ENTERPRISE_PLAN = "Enterprise";

export const HTTP_METHODS = {
    GET: "get",
    POST: "post",
    PUT: "put",
    DELETE: "delete",
};

export const EXCLUDE_TOAST_CODES = [HTTP_STATUS_CODES.LOGGED_IN, HTTP_STATUS_CODES.USER_INFO];
export const NameColumn = ({ value, action, type }) => {
    const navigate = useNavigate();
    const isAction = action !== undefined;
    const navigateToUrl = () => {
        if (action) {
            if (typeof action === "string") {
                navigate(action + "?id=" + value._id);
            } else {
                action();
            }
        }
    };
    return (
        <>
            {value?.name ? (
                <div className={"flex items-center "} onClick={navigateToUrl}>
                    <div
                        className={
                            "flex items-center p-1 bg-blue-50 border-blue-200 border rounded-md dark:bg-gray-700 dark:border-gray-700"
                        }
                    >
                        <IconText
                            icon={navigationIcon[type]}
                            className={"text-sm text-blue-700 opacity-80 dark:text-white dark:opacity-100"}
                        />
                    </div>
                    <span
                        className={classNames(
                            "ml-2 rtl:mr-2 capitalize font-bold",
                            isAction ? "cursor-pointer text-blue-600 underline" : "",
                        )}
                    >
                        {value?.name.length > 15 ? value?.name.substring(0, 15) + "..." : value?.name}
                    </span>
                </div>
            ) : (
                <div className={"flex items-center "} onClick={navigateToUrl}>
                    <div
                        className={
                            "flex items-center p-1 bg-blue-50 border-blue-200 border rounded-md  dark:bg-gray-700 dark:border-gray-700"
                        }
                    >
                        <IconText
                            icon={navigationIcon[type]}
                            className={"text-sm text-blue-700 opacity-80  dark:text-white dark:opacity-100"}
                        />
                    </div>
                    <span
                        className={classNames(
                            "ml-2 rtl:mr-2 capitalize font-bold",
                            isAction ? "cursor-pointer text-blue-600 underline" : "",
                        )}
                    >
                        {value?.lastName.length > 15 ? value?.lastName.substring(0, 15) + "..." : value?.lastName}
                    </span>
                </div>
            )}
        </>
    );
};

export const PhoneColumn = ({ value, action, type }) => {
    const navigate = useNavigate();
    const isAction = action !== undefined;
    const Number = value?.phoneNumber;
    const countryCode = 1;
    const navigateToUrl = () => {
        if (action) {
            if (typeof action === "string") {
                navigate(action + "?id=" + value._id);
            } else {
                action();
            }
        }
    };
    return (
        <>
            {value?.name ? (
                <div className={"flex items-center "} onClick={navigateToUrl}>
                    <div
                        className={
                            "flex items-center p-1 bg-blue-50 border-blue-200 border rounded-md  dark:bg-gray-700 dark:border-gray-700"
                        }
                    >
                        <IconText
                            icon={navigationIcon[type]}
                            className={"text-sm text-blue-700 opacity-80  dark:text-white dark:opacity-100"}
                        />
                    </div>
                    <span
                        className={classNames(
                            "ml-2 rtl:mr-2 capitalize font-bold",
                            isAction ? "cursor-pointer text-blue-600 underline" : "",
                        )}
                    >
                        <PhoneNumberFormatter number={Number} countryCode={countryCode} />
                        {/*{value?.name.length > 15 ? value?.name.substring(0, 15) + "..." : value?.name}*/}
                    </span>
                </div>
            ) : (
                <div className={"flex items-center "} onClick={navigateToUrl}>
                    <div
                        className={
                            "flex items-center p-1 bg-blue-50 border-blue-200 border rounded-md  dark:bg-gray-700 dark:border-gray-700"
                        }
                    >
                        <IconText
                            icon={navigationIcon[type]}
                            className={"text-sm text-blue-700 opacity-80  dark:text-white dark:opacity-100"}
                        />
                    </div>
                    <span
                        className={classNames(
                            "ml-2 rtl:mr-2 capitalize font-bold",
                            isAction ? "cursor-pointer text-blue-600 underline" : "",
                        )}
                    >
                        <PhoneNumberFormatter number={Number} countryCode={countryCode} />
                        {/*{value?.lastName.length > 15 ? value?.lastName.substring(0, 15) + "..." : value?.lastName}*/}
                    </span>
                </div>
            )}
        </>
    );
};

export const GETTING_STARTED = [
    {
        title: "Getting Started with Treply",
        options: [
            {
                title: "What is Treply?",
                description:
                    "<blockquÎote><p>Treply is an innovative SMS marketing platform that enables businesses to harness the power of text messaging as a vital component of our marketing strategy.</p></blockquÎote>" +
                    "<p class='mt-4'>Treply focuses exclusively on SMS, offering a suite of tools and features to facilitate personalized and highly engaging communication with customers.</p>" +
                    "<p class='mt-4'>With Treply, businesses can create and execute SMS marketing campaigns, automate messages, and deliver targeted content to segmented customer groups.</p>" +
                    "<p class='mt-4'>It leverages the immediacy, high open rates, and conversational nature of SMS to drive customer engagement, recover abandoned carts, nurture loyalty, and achieve marketing objectives.</p>" +
                    "<p class='mt-4'>Treply empowers businesses to make data-driven decisions, providing insights into campaign performance, open rates, and customer responses. Whether it's sending timely notifications, offering exclusive discounts, or reengaging customers, Treply maximizes the potential of SMS marketing, helping businesses connect with their audience right where they are on their phones.</p>",
                navigate: {
                    isVisible: false,
                },
                skip: {
                    isVisible: true,
                    title: "Next",
                },
            },
            {
                title: "How do i use Treply?",
                description:
                    "Step 1: Add a Contact.<br/>Step 2: Add a Group<br/>Step 3: Add a Channel<br/>Step 4: Add a Campaign<br/>Step 5: Add a Survey<br/>Step 6: Verify User Information<br/>Step 7: Verify Business Information<br/>Step 8: Verify Toll-Free Number",
                navigate: {
                    isVisible: false,
                },
                skip: {
                    isVisible: false,
                },
            },
        ],
    },

    {
        title: "Communicate with your Audience",
        options: [
            {
                title: "Add a Channel (Toll-Free Number)",
                description:
                    "Set up a unique toll-free phone number exclusively for your business. This dedicated number serves as a direct communication channel for your customers, allowing them to reach out to you easily and hassle-free.",
                navigate: {
                    isVisible: true,
                    title: "Channels",
                    navigateUrl: appConfig.newChannel,
                },
                skip: {
                    isVisible: true,
                    title: "Skip",
                },
            },
            {
                title: "Add a Contact to the Channel",
                description:
                    "To connect with your customers seamlessly, you can add a contact to a specific channel. This means that you're associating a customer's phone number with a particular communication channel, such as your dedicated toll-free number. Once added, the customer can use this channel to engage in one-on-one conversations with your business. This is a convenient way to provide personalized support and assistance, making communication efficient and direct.",
                navigate: {
                    isVisible: true,
                    title: "Channels",
                    navigateUrl: "/chat",
                },
                skip: {
                    isVisible: true,
                    title: "Skip",
                },
            },
            {
                title: "Send a Message",
                description:
                    "To communicate with your customers or prospects, you can use Treply to craft and send messages. These messages can be personalized and tailored to your recipients. You have the flexibility to schedule messages for a specific time or send them immediately. This feature allows you to reach your audience with timely information, promotions, or updates, ensuring that your communication is both relevant and effective.",
                navigate: {
                    isVisible: true,
                    title: "Channels",
                    navigateUrl: "/chat",
                },
                skip: {
                    isVisible: false,
                },
            },
        ],
    },
    {
        title: "Grow your Audience",
        options: [
            {
                title: "Add a Contact",
                description:
                    "egin by inputting your customer's contact information into Treply. This includes their phone numbers and other relevant details. This step sets the foundation for effective communication and engagement.",
                navigate: {
                    isVisible: true,
                    title: "Contacts",
                    navigateUrl: "/audience/contacts",
                },
                skip: {
                    isVisible: true,
                    title: "Skip",
                },
            },
            {
                title: "Add a Group",
                description:
                    "Arrange your contacts into different groups for better management and targeted communication. By categorizing your contacts into groups, you can send relevant messages to specific customer segments, ensuring your communication is tailored to their needs and interests.",
                navigate: {
                    isVisible: true,
                    title: "Groups",
                    navigateUrl: "/audience/groups",
                },
                skip: {
                    isVisible: false,
                },
            },
        ],
    },
    {
        title: "Use the Marketing Tools",
        options: [
            {
                title: "Add a Survey",
                description:
                    "With Treply, you can easily create surveys to gather valuable feedback, opinions, or data from your customers. You have the flexibility to send these surveys multiple times to the same or different users. This feature empowers you to gain insights and understand your customers' preferences and needs better. It's a valuable tool for enhancing your products, services, and overall customer experience.",
                navigate: {
                    isVisible: true,
                    title: "Surveys",
                    navigateUrl: "/surveys",
                },
                skip: {
                    isVisible: true,
                    title: "Skip",
                },
            },
            {
                title: "Add a Campaign",
                description:
                    "Treply allows you to design and create marketing campaigns that are tailored to your specific audience. You have the flexibility to schedule these campaigns for a specific time or send them immediately. This feature empowers you to reach your customers with targeted messages, whether it's promotions, product updates, or important information. It's a powerful tool for engaging your audience and achieving your marketing goals.",
                navigate: {
                    isVisible: true,
                    title: "Campaigns",
                    navigateUrl: "/campaigns",
                },
                skip: {
                    isVisible: false,
                },
            },
        ],
    },
    {
        title: "Verify your Account",
        options: [
            {
                title: "Verify General User Information",
                description:
                    "Verifying user information is a crucial step to ensure accuracy and security in your communication. This process typically involves confirming and validating the details provided by users, such as their identity, contact information, or other relevant data. By verifying user information, you can maintain data integrity, reduce the risk of fraudulent activity, and enhance the reliability of your interactions. This step is essential for building trust and ensuring the effectiveness of your communication and services.",
                navigate: {
                    isVisible: true,
                    title: "User Information",
                    navigateUrl: "/app/settings/general",
                },
                skip: {
                    isVisible: true,
                    title: "Skip",
                },
            },
            {
                title: "Verify Business Information",
                description:
                    "Verifying business information is a vital process aimed at confirming the accuracy and legitimacy of a company's details. This often involves validating critical information such as the business's name, address, contact information, registration, and other relevant data. Verifying business information is essential for establishing trust with customers, partners, and regulatory authorities. It helps prevent fraud, ensures transparency, and enhances the credibility of the business in its interactions and transactions. This process is a fundamental step in maintaining the integrity of business operations and building a reputable brand.",
                navigate: {
                    isVisible: true,
                    title: "Business Information",
                    navigateUrl: "/app/settings/organization",
                },
                skip: {
                    isVisible: true,
                    title: "Skip",
                },
            },
            {
                title: "Verify Toll-Free Number",
                description:
                    "Verifying a toll-free number is a crucial step to ensure its authenticity and reliability. This process involves confirming that the provided toll-free number is legitimate and associated with the intended business or organization. Verification helps prevent fraudulent or misleading use of toll-free numbers, ensuring that customers can trust that they are contacting the correct entity when using this contact channel. This verification adds an extra layer of trust and credibility to the communication, enhancing the overall customer experience and preventing misuse of toll-free numbers.",
                navigate: {
                    isVisible: true,
                    title: "Verify Toll-Free Number",
                    navigateUrl: "/app/settings/organization",
                },
                skip: {
                    isVisible: false,
                },
            },
        ],
    },
];

export const getTag = (value, type, isPrefix, className) => {
    if (type === "success") {
        return (
            <Tag
                prefix={isPrefix}
                prefixClass={"bg-emerald-600"}
                className={`${className} border-2 border-emerald-300 bg-emerald-100 text-emerald-600 dark:bg-emerald-500/20 dark:text-emerald-100`}
            >
                {value}
            </Tag>
        );
    } else if (type === "error") {
        return (
            <Tag
                prefix={isPrefix}
                prefixClass={"bg-red-600"}
                className={`${className} border-2 border-red-300 bg-red-100 text-red-600 dark:bg-red-500/20 dark:text-red-100 rounded-2`}
            >
                {value}
            </Tag>
        );
    } else if (type === "info") {
        return (
            <Tag
                prefix={isPrefix}
                prefixClass={"bg-blue-600"}
                className={`${className} border-2 border-blue-300 bg-blue-100 text-blue-600 dark:bg-blue-500/20 dark:text-blue-100 rounded-2`}
            >
                {value}
            </Tag>
        );
    } else if (type === "warning") {
        return (
            <Tag
                prefix={isPrefix}
                prefixClass={"bg-orange-600"}
                className={`${className} border-2 border-orange-300 bg-orange-100 text-orange-600 dark:bg-orange-500/20 dark:text-orange-100 rounded-2`}
            >
                {value}
            </Tag>
        );
    }
};

export const getDashboardCell = (props, colName) => {
    const { original } = props?.row;
    if (original[colName] === "-") {
        return (
            <div className="flex items-center">
                <div className="ml-1">
                    <div className="text-sm text-gray-900 dark:text-gray-100">&nbsp;</div>
                </div>
            </div>
        );
    } else {
        return (
            <div className="flex items-center">
                <div className="ml-1">
                    <div className="text-sm text-gray-900 dark:text-gray-100">{original[colName]}</div>
                </div>
            </div>
        );
    }
};

export function formatPhoneNumber(number, countryCode) {
    const cleaned = ("" + number).replace(/\D/g, ""); // Remove non-digit characters

    if (countryCode === "1" && cleaned.length === 11) {
        return `+${countryCode} (${cleaned.substring(1, 4)}) ${cleaned.substring(4, 7)}-${cleaned.substring(7)}`;
    }

    // Return original number with country code and custom grouping
    const formattedNumber = `+${countryCode} (${cleaned.substring(1, 4)}) ${cleaned.substring(
        4,
        7,
    )}-${cleaned.substring(7)}`;
    return formattedNumber;
}

export const HEADER_TOOLTIP = {
    ownerTitle: "You are an Owner of this channel",
    participantTitle: "You are a Participant of this channel",
    adminTitle: "You are an Admin of this channel",
};

export function formatTime(utcDate) {
    // Convert UTC date to local date
    const localDate = moment.utc(utcDate).local();
    return localDate.format("h:mm A"); // e.g., 3:30 PM
}

export function formatDate(utcDate) {
    // Convert UTC date to local date
    const localDate = moment.utc(utcDate).local();

    // Check if the date is today
    if (localDate.isSame(moment(), "day")) {
        return localDate.format("h:mm A"); // e.g., 3:30 PM
    }

    // Check if the date was yesterday
    if (localDate.isSame(moment().subtract(1, "days"), "day")) {
        return "Yesterday";
    }

    // Check if the date was two days ago
    if (localDate.isSame(moment().subtract(2, "days"), "day")) {
        return localDate.format("dddd"); // e.g., Tuesday
    }

    // For other dates, you can return a different format or extend the logic
    return localDate.format("MMM D"); // e.g., Mar 19, 2024
}

// Example usage
const formattedDate = formatDate("2024-03-19T12:30:00Z");

export function formatCompactDateTime(dateTimeString) {
    const date = new Date(dateTimeString);

    // Get the individual date components
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours() % 12 || 12).padStart(2, "0"); // Convert to 12-hour format
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const ampm = date.getHours() >= 12 ? "PM" : "AM";

    // Construct the formatted compact date and time string with dashes
    const formattedCompactDateTime = `${month}-${day} ${hours}:${minutes} ${ampm}`;

    return formattedCompactDateTime;
}

export const PAGE_SIZE_OPTION = [
    { value: 20, label: "20 / Page" },
    { value: 40, label: "40 / Page" },
    { value: 60, label: "60 / Page" },
    { value: 80, label: "80 / Page" },
    { value: 100, label: "100 / Page" },
];
