import { SIZES } from "../components/ui/utils/constant";
import { DIR_LTR, LAYOUT_TYPE_MODERN, MODE_LIGHT, NAV_MODE_TRANSPARENT } from "../constants/theme.constant";

export const themeConfig = {
    themeColor: "blue",
    direction: DIR_LTR,
    mode: MODE_LIGHT,
    locale: "en",
    primaryColorLevel: 600,
    cardBordered: true,
    panelExpand: false,
    controlSize: SIZES.MD,
    navMode: NAV_MODE_TRANSPARENT,
    layout: {
        type: LAYOUT_TYPE_MODERN,
        sideNavCollapse: false,
    },
};
