import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    avatar: "/img/avatars/avatar.jpg",
    userName: "",
    email: "",
    authority: [],
    firstName: "",
    lastName: "",
    auth: {
        user: {
            organizationDetails: {},
        },
    },
};

export const userSlice = createSlice({
    name: "auth/user",
    initialState,
    reducers: {
        setUser: (_, action) => {
            return {
                ...action.payload,
            };
        },
        updateOrganizationDetails: (state, action) => {
            // console.log("Update organization details called with payload:", action.payload);
            if (!state.auth || !state.auth.user) {
                state.auth = {
                    user: {
                        organizationDetails: {},
                    },
                };
            }
            if (!state.auth.user.organizationDetails) {
                state.auth.user.organizationDetails = {};
            }
            // console.log("Current organization details:", state.auth.user.organizationDetails);
            state = {
                ...state.auth.user.organizationDetails,
                ...action.payload,
            };
            // console.log("Updated organization details:", state);
        },
        updateUser: (state, action) => {
            return {
                ...state,
                ...action.payload,
            };
        },
        userLoggedOut: () => initialState,
    },
});

export const { setUser, updateUser, updateOrganizationDetails } = userSlice.actions;

export default userSlice.reducer;
