import { createContext, useContext } from "react";
import { SIZES } from "../utils/constant";
import {
    DIR_LTR,
    LAYOUT_TYPE_MODERN,
    MODE_DARK,
    NAV_MODE_DARK,
    NAV_MODE_TRANSPARENT,
} from "../../../constants/theme.constant";

export const defaultConfig = {
    themeColor: "blue",
    direction: DIR_LTR,
    mode: MODE_DARK,
    locale: "en",
    primaryColorLevel: 600,
    cardBordered: true,
    panelExpand: false,
    controlSize: SIZES.XS,
    navMode: NAV_MODE_TRANSPARENT,
    layout: {
        type: LAYOUT_TYPE_MODERN,
        sideNavCollapse: false,
    },
};

export const ConfigContext = createContext();

const ConfigProvider = ConfigContext.Provider;

export const ConfigConsumer = ConfigContext.Consumer;

export function useConfig() {
    return useContext(ConfigContext);
}

export default ConfigProvider;
