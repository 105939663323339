import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

const isProduction = process.env.REACT_APP_ENV === "production";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log)
if (isProduction) {
    // Add Google Tag Manager script in production only
    const script = document.createElement("script");
    script.innerHTML = `
    (function(w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
      var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : '';
      j.async = true;
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
      f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', 'GTM-T5M68STW');
  `;
    document.head.appendChild(script);

    // Add the noscript tag for GTM
    const noscript = document.createElement("noscript");
    noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-T5M68STW" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
    document.body.appendChild(noscript);

    // Import and call reportWebVitals only in production
    import("./reportWebVitals").then(({ default: reportWebVitals }) => {
        reportWebVitals(console.log);
    });
}
