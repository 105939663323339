import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    globalAlert: [],
};

export const globalAlertSlice = createSlice({
    name: "alert",
    initialState,
    reducers: {
        setGlobalAlert: (state, action) => {
            const { component, alertType, remaining } = action.payload;
            const existingAlertIndex = state.globalAlert.findIndex(
                (alert) => alert.component === component && alert.alertType === alertType,
            );
            if (existingAlertIndex !== -1) {
                if (remaining < state.globalAlert[existingAlertIndex].remaining) {
                    state.globalAlert[existingAlertIndex] = action.payload;
                }
            } else {
                state.globalAlert.push(action.payload);
            }

            state.globalAlert.sort((a, b) => {
                if (a.alertType === "warning" && b.alertType === "danger") {
                    return -1; // a should come before b
                } else if (a.alertType === "danger" && b.alertType === "warning") {
                    return 1; // b should come before a
                } else {
                    return 0; // maintain current order
                }
            });
        },

        removeGlobalAlert: (state, action) => {
            if (state.globalAlert.length > 0) {
                state.globalAlert.splice(0, 1); // Remove the first element
            } else {
                console.error("No alerts to remove");
            }
        },
    },
});

export const { setGlobalAlert, removeGlobalAlert } = globalAlertSlice.actions;

export default globalAlertSlice.reducer;
