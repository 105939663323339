import { APP_PREFIX_PATH } from "../constants/route.constant";

const appConfig = {
    baseURL: process.env.REACT_APP_BASE_URL || "http://localhost:8080/api",
    apiPrefix: "/api",
    resourcesPath: "/resources",
    goLivePath: "/go-live",
    authenticatedEntryPath: "/dashboard",
    pricingPage: "/onboarding/pricing",
    unAuthenticatedEntryPath: "/auth0-login",
    signInPath: "/auth0-login",
    tourPath: "/app/account/kyc-form",
    emailVerification: "/email-verification",
    accessDeniedPath: "/access-denied",
    enableMock: false,
    // newChannel: `${APP_PREFIX_PATH}/settings/channels?type=new`,
    editChannel: `/settings/channels?type=edit`,
    verifyChannel: `/settings/channels`,
};

export default appConfig;
