import ApiService from "./ApiService";

export async function signUpApi(userDetails, next) {
    try {
        await ApiService.fetchData({
            url: "/users/account",
            method: "post",
            data: userDetails,
        });
        return next({
            status: "success",
        });
    } catch (err) {
        console.error(err);
        next({
            status: "failed",
            message: err.response.data.message,
        });
    }
}

export async function apiOnBoarding(data) {
    return ApiService.fetchData({
        url: "/users/onboarding",
        method: "post",
        data: data,
    });
}

export async function updateOnboarding(data) {
    return ApiService.fetchData({
        url: "/users/onboarding",
        method: "put",
        data: data,
    });
}

export async function apiUpdateOnBoardingStep(onboardingStep) {
    return ApiService.fetchData({
        url: "/users/onboarding/update",
        method: "post",
        data: { onboardingStep },
    });
}

export async function apiUpdateInvitedUserDetails(data) {
    return ApiService.fetchData({
        url: "/users/update/invited-users",
        method: "put",
        data: data,
    });
}

export async function apiUserInfo(data) {
    return ApiService.fetchData({
        url: "/users/info",
        method: "post",
        data: data,
    });
}

export async function apiGetActiveUsers() {
    return ApiService.fetchData({
        url: "/users/active-users",
        method: "get",
    });
}
