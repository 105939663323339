// DataTable.jsx

import React, { forwardRef, useEffect, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import { Checkbox, Pagination, Select, Table } from "components/ui";
import TableRowSkeleton from "./loaders/TableRowSkeleton";
import Loading from "./Loading";
import { usePagination, useRowSelect, useSortBy, useTable } from "react-table";

const { Tr, Th, Td, THead, TBody, Sorter } = Table;

const IndeterminateCheckbox = forwardRef((props, ref) => {
    const { indeterminate, onChange, onCheckBoxChange, onIndeterminateCheckBoxChange, ...rest } = props;

    const defaultRef = useRef();
    const resolvedRef = ref || defaultRef;

    useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    const handleChange = (e) => {
        onChange(e);
        onCheckBoxChange?.(e);
        onIndeterminateCheckBoxChange?.(e);
    };

    return <Checkbox className="mb-0" ref={resolvedRef} onChange={(_, e) => handleChange(e)} {...rest} />;
});

const DataTable = (props) => {
    const {
        skeletonAvatarColumns,
        className,
        columns,
        data,
        loading,
        onCheckBoxChange,
        onIndeterminateCheckBoxChange,
        onPaginationChange,
        onSelectChange,
        onSort,
        pageSizes,
        selectable,
        skeletonAvatarProps,
        pagingData,
        autoResetSelectedRows,
        hidePagination,
        hideHeader,
        showInActive,
    } = props;

    const { pageSize, pageIndex, total } = pagingData;

    const pageSizeOption = useMemo(
        () => pageSizes.map((number) => ({ value: number, label: `${number} / page` })),
        [pageSizes],
    );

    const handleCheckBoxChange = (checked, row) => {
        if (!loading) {
            onCheckBoxChange?.(checked, row);
        }
    };

    const handleIndeterminateCheckBoxChange = (checked, rows) => {
        if (!loading) {
            onIndeterminateCheckBoxChange?.(checked, rows);
        }
    };

    const handleSort = (column) => {
        if (!loading) {
            const { id, isSortedDesc, toggleSortBy, clearSortBy } = column;
            const sortOrder = isSortedDesc ? "desc" : "asc";
            toggleSortBy(!isSortedDesc);
            onSort?.({ order: sortOrder, key: id }, { id, clearSortBy });
        }
    };

    const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } = useTable(
        {
            className,
            columns,
            data,
            manualPagination: true,
            manualSortBy: true,
            autoResetSelectedRows,
        },
        useSortBy,
        usePagination,
        useRowSelect,
        (hooks) => {
            if (selectable) {
                hooks.visibleColumns.push((columns) => [
                    {
                        id: "selection",
                        Header: (props) => (
                            <div>
                                <IndeterminateCheckbox
                                    {...props.getToggleAllRowsSelectedProps()}
                                    onIndeterminateCheckBoxChange={(e) =>
                                        handleIndeterminateCheckBoxChange(e.target.checked, props.rows)
                                    }
                                />
                            </div>
                        ),
                        Cell: ({ row }) => (
                            <div>
                                <IndeterminateCheckbox
                                    {...row.getToggleRowSelectedProps()}
                                    onCheckBoxChange={(e) => handleCheckBoxChange(e.target.checked, row.original)}
                                />
                            </div>
                        ),
                        sortable: false,
                    },
                    ...columns,
                ]);
            }
        },
    );

    const handlePaginationChange = (page) => {
        if (!loading) {
            onPaginationChange?.(page);
        }
    };

    const handleSelectChange = (value) => {
        if (!loading) {
            onSelectChange?.(Number(value));
        }
    };

    return (
        <Loading loading={loading && data.length !== 0} type="cover">
            <Table {...getTableProps()} className={className}>
                {!hideHeader && (
                    <THead>
                        {headerGroups.map((headerGroup) => (
                            <Tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <Th
                                        {...column.getHeaderProps(column.getSortByToggleProps())} // Add the getSortByToggleProps() method
                                        className={column.sortable ? "cursor-pointer" : ""}
                                        onClick={column.sortable ? () => handleSort(column) : null} // Add the onClick event handler
                                    >
                                        {column.render("Header") &&
                                            (column.sortable ? (
                                                <div>
                                                    {column.render("Header")}
                                                    <span>
                                                        <Sorter sort={column.isSortedDesc} />
                                                    </span>
                                                </div>
                                            ) : (
                                                <div>{column.render("Header")}</div>
                                            ))}
                                    </Th>
                                ))}
                            </Tr>
                        ))}
                    </THead>
                )}
                {loading && data.length === 0 ? (
                    <TableRowSkeleton
                        columns={columns.length}
                        rows={pagingData.pageSize}
                        avatarInColumns={skeletonAvatarColumns}
                        avatarProps={skeletonAvatarProps}
                    />
                ) : (
                    <TBody {...getTableBodyProps()}>
                        {page.map((row, i) => {
                            prepareRow(row);
                            const rowClass =
                                showInActive && !row?.original?.user?.active ? "opacity-20 cursor-not-allowed" : "";

                            return (
                                <Tr {...row.getRowProps()} className={rowClass}>
                                    {row.cells.map((cell) => {
                                        return (
                                            <Td className="!h-[52px]" {...cell.getCellProps()}>
                                                {cell.render("Cell")}
                                            </Td>
                                        );
                                    })}
                                </Tr>
                            );
                        })}
                    </TBody>
                )}
            </Table>

            {!hidePagination && ( // Render the pagination only if hidePagination prop is false
                <div className={`${total > 10 ? "flex" : "hidden"}`}>
                    <div className="flex items-center justify-between mt-4">
                        <Pagination
                            pageSize={pageSize}
                            currentPage={pageIndex}
                            total={total}
                            onChange={handlePaginationChange}
                        />
                        <div style={{ minWidth: 130 }}>
                            <Select
                                size="sm"
                                menuPlacement="top"
                                isSearchable={false}
                                value={pageSizeOption.filter((option) => option.value === pageSize)}
                                options={pageSizeOption}
                                onChange={(option) => handleSelectChange(option.value)}
                            />
                        </div>
                    </div>
                </div>
            )}
        </Loading>
    );
};

DataTable.propTypes = {
    className: PropTypes.string,
    columns: PropTypes.array,
    data: PropTypes.array,
    loading: PropTypes.bool,
    onCheckBoxChange: PropTypes.func,
    onIndeterminateCheckBoxChange: PropTypes.func,
    onPaginationChange: PropTypes.func,
    onSelectChange: PropTypes.func,
    onSort: PropTypes.func,
    pageSizes: PropTypes.arrayOf(PropTypes.number),
    selectable: PropTypes.bool,
    skeletonAvatarColumns: PropTypes.arrayOf(PropTypes.number),
    skeletonAvatarProps: PropTypes.object,
    pagingData: PropTypes.shape({
        total: PropTypes.number,
        pageIndex: PropTypes.number,
        pageSize: PropTypes.number,
    }),
    autoResetSelectedRows: PropTypes.bool,
    hidePagination: PropTypes.bool,
    hideHeader: PropTypes.bool,
};

DataTable.defaultProps = {
    pageSizes: [10, 25, 50, 100],
    pagingData: {
        total: 0,
        pageIndex: 1,
        pageSize: 10,
    },
    data: [],
    className: "",
    columns: [],
    selectable: false,
    loading: false,
    autoResetSelectedRows: true,
    hidePagination: false,
    hideHeader: false,
};

export default DataTable;
