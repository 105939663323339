import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "./store";
import Theme from "components/template/Theme";
import Layout from "components/layout";
import history from "./history";
import "./locales";
import { Auth0Provider } from "@auth0/auth0-react";

function App() {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <BrowserRouter history={history}>
                    <Auth0Provider
                        domain={process.env.REACT_APP_AUTH0_DOMAIN}
                        clientId={process.env.REACT_APP_AUTH0_CLIENTID}
                        cacheLocation={"localstorage"}
                        authorizationParams={{
                            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
                            redirect_uri: window.location.origin + "/callback",
                            scope: "openid profile email offline_access",
                        }}
                    >
                        <Theme>
                            <Layout />
                        </Theme>
                    </Auth0Provider>
                </BrowserRouter>
            </PersistGate>
        </Provider>
    );
}

export default App;
