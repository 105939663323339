import axios from "axios";
import appConfig from "configs/app.config";
import { REQUEST_HEADER_AUTH_KEY, TOKEN_TYPE } from "constants/api.constant";
import { ACCESS_TOKEN, HTTP_STATUS_CODES } from "constants/app.constant";
import React from "react";
import { Navigate } from "react-router-dom";

const unauthorizedCode = [401];

const BaseService = axios.create({
    timeout: 60000 * 5,
    baseURL: appConfig.baseURL,
});

BaseService.interceptors.request.use(
    (config) => {
        const accessToken = localStorage.getItem(ACCESS_TOKEN)?.trim();
        if (!!accessToken) {
            config.headers[REQUEST_HEADER_AUTH_KEY] = `${TOKEN_TYPE}${accessToken}`;
        } else {
            return <Navigate to={"/signout"} />;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);

BaseService.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (unauthorizedCode.includes(error.response?.status)) {
            localStorage.removeItem(ACCESS_TOKEN);
            sessionStorage.clear();

            window.location.href = "/signout"; // Redirect to login or handle logout
        }
        if (error?.code && error.code === HTTP_STATUS_CODES.ERR_NETWORK) {
            return <Navigate to={"/error-page"} />;
        }
        return Promise.reject(error);
    },
);

export default BaseService;
