import BaseService from "./BaseService";

const ApiService = {
    fetchData(param, isAllData) {
        return new Promise((resolve, reject) => {
            BaseService(param)
                .then((response) => {
                    if (isAllData) {
                        resolve(response?.data);
                    } else {
                        resolve(response?.data?.data);
                    }
                })
                .catch((errors) => {
                    if (errors?.response?.status === 401) {
                        localStorage.clear();
                        window.location.href = "/";
                    }
                    reject(errors);
                });
        });
    },
};

export default ApiService;
