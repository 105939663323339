import React from "react";
import { formatPhoneNumber } from "../constants/app.constant";

const PhoneNumberFormatter = ({ number, countryCode, className }) => {
    const formattedNumber = formatPhoneNumber(number, countryCode);
    const cn = className ? className : "phone-number-formatter";
    return <div className={cn}>{formattedNumber}</div>;
};

export default PhoneNumberFormatter;
